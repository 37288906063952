export const PROJECTS = [
    {
        id: 'project6',
        title: 'Tapestry',
        subTitle: 'by The Thesis Group',
        pages: [
            {
                num: 1,
                title: 'Tapestry',
                article: {
                    header: 'Overview',
                    text: 'This game came out of our thesis project for our education in Games at the IT University of Copenhagen. The game is about exploring the ruins of an ancient civilisation which held power over a magic language. To explore the game the player will have to learn this language and use it to materialise objects in the world',
                },
                info: [
                    {
                        title: 'Team Size',
                        text: '4'
                    },
                    {
                        title: 'Roles',
                        text: 'Level Design, Gameplay Design, Evironment Art, C# Programmer'
                    },
                    {
                        title: 'Duration',
                        text: '4 months'
                    },
                    {
                        title: 'Software',
                        text: 'Unity and Blender'
                    },
                    {
                        title: 'Platform',
                        text: 'PC'
                    }
                ],
                link: "https://drive.google.com/file/d/1yh9ZBKBvGrEvUtW3ah_WvJ53ChPf1hUI/view?usp=sharing"
            },
            {
                num: 2,
                gallery: [
                    {
                        imgSrcHR: 'Tapestry-intro.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'The Final Tower',
                        descrp: 'Upon entering the outside of the game world the player will notice a tower in the distance. Their goal is to reach this tower.'
                    },
                    {
                        imgSrcHR: 'Tapestry-waterPuzzle.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Crossing a chasm',
                        descrp: 'The player can change the matter of objects. In this case the player has changed the matter of an object to water which allows them to cross the chasm.'
                    },
                    {
                        imgSrcHR: 'Tapestry-gainingRadical.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: '',
                        descrp: 'To write in this magical language the player must find radicals to construct the glyphs of the language. They are scattered around the world.'
                    },
                    {
                        imgSrcHR: 'Tapestry-steamPuzzle.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Crossing a broken bridge',
                        descrp: 'The player can further manipulate the matter of objects. In this case a glyph representing "hot" has been placed after the water glyph turning it into steam.'
                    },
                    {
                        imgSrcHR: 'Tapestry-matchingPuzzle.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Matching puzzle',
                        descrp: 'There are also puzzles where the player will have to match what is in the small dome to the right in the big dome to the left.'
                    },
                    {
                        imgSrcHR: 'Tapestry-elevator.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Elevator',
                        descrp: 'When correctly matching the two domes the elevator will activate granting the player access to a new area.'
                    }
                ]
            }
        ]
    },
    {
        id: 'project5',
        title: 'The Great Oaks',
        subTitle: 'by GWD Team 8',
        pages: [
            {
                num: 1,
                title: 'The Great Oaks',
                article: {
                    header: 'Overview',
                    text: 'A game made for the course Game World Design in the Games program at the IT University of Copenhagen. It is a 2 player co-op game where the 2 players has to help each other move forward.'
                },
                info: [
                    {
                        title: 'Team Size',
                        text: '6'
                    },
                    {
                        title: 'Roles',
                        text: 'UI Design, Character Design, Evironment Art'
                    },
                    {
                        title: 'Duration',
                        text: '3 months'
                    },
                    {
                        title: 'Software',
                        text: 'Unity, Blender and Illustrator'
                    },
                    {
                        title: 'Platform',
                        text: 'PC'
                    }
                ],
                link: 'https://barrythecoolbee.itch.io/the-great-oaks'
            },
            {
                num: 2,
                gallery: [
                    {
                        imgSrcHR: 'TheGreatOaks-menu.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Menu',
                        descrp: 'When the game starts each player has to pick a character.'
                    },
                    {
                        imgSrcHR: 'TheGreatOaks-frogHouse.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Frog House',
                        descrp: 'Players can control the dialogue of the character they picked.'
                    },
                    {
                        imgSrcHR: 'TheGreatOaks-plaforming.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Platforming',
                        descrp: 'there are platforming challenges in the game where the players will have to help each other to get across.'
                    },
                    {
                        imgSrcHR: 'TheGreatOaks-cabbageHouse.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Abandoned House',
                        descrp: 'Throughout the journey the players will get to know the two characters.'
                    },
                    {
                        imgSrcHR: 'TheGreatOaks-magicRobotThing.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Robot Relic',
                        descrp: 'They will discover secrets hidden in the environment.'
                    }
                ]
            }
        ]
    },
    {
        id: 'project4',
        title: 'The Guardener',
        subTitle: 'by Safe Space*',
        pages: [
            {
                num: 1,
                title: 'The Guardener',
                article: {
                    header: 'Overview',
                    text: 'This was a game made for the course Making Games in the Games program at the IT University of Copenhagen. The game is a take on the tower defense genre where the player is an active agent in defending instead of the classic structure of just waiting for the wave to be over. The theme of the game is inspired by organic gardening with some magic elements where the player has to defend their garden from unwanted pests.'
                },
                info: [
                    {
                        title: 'Team Size',
                        text: '5'
                    },
                    {
                        title: 'Roles',
                        text: 'UI Design, UX, QA, Art Direction'
                    },
                    {
                        title: 'Duration',
                        text: '2 months'
                    },
                    {
                        title: 'Software',
                        text: 'Unity, Blender and Illustrator'
                    },
                    {
                        title: 'Platform',
                        text: 'PC'
                    }
                ],
                link: 'https://drive.google.com/file/d/1xt9z_S5R_25nqbE8_XVJF_Mtanxqo8mV/view?usp=sharing'
            },
            {
                num: 2,
                gallery: [
                    {
                        imgSrcHR: 'TheGuardenerPlacingCoffee.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Strategy Mode',
                        descrp: 'There are two modes in the game where the first mode is the strategy mode.'
                    },
                    {
                        imgSrcHR: 'TheGuardenerPlacingCatapult.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Strategy Mode',
                        descrp: 'In the strategy mode the player places the defensive objects and plans out how best to defend their crops.'
                    },
                    {
                        imgSrcHR: 'TheGuardenerPlacingFlowers.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Strategy Mode',
                        descrp: 'The player can also invest in crops that give them extra materials to build better defensive objects or plants that can heal the player character.'
                    },
                    {
                        imgSrcHR: 'TheGuardenerAttack.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Combat Mode',
                        descrp: 'The second mode is the defend mode.'
                    },
                    {
                        imgSrcHR: 'TheGuardenerAttack2.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Combat Mode',
                        descrp: 'In this mode the pests come in waves and try to eat the players crops, attack the house and attack the player character.'
                    },
                    {
                        imgSrcHR: 'TheGuardenerRunningFromAnts.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Combat Mode',
                        descrp: 'If either the player character dies or the house is destroyed it is game over.'
                    },
                    {
                        imgSrcHR: 'the_guardener_img3.png',
                        imgSrcLR: '',
                        alt: 'Menu',
                        descrp: 'The style of the UI is inspired by Cletic symbols and shapes found in nature.'
                    },
                    {
                        imgSrcHR: 'TheGuardenerMenu.gif',
                        imgSrcLR: '',
                        frame: 'gif_frame.png',
                        alt: 'Menu',
                        descrp: 'The visual style of the UI needed to be simplistic as the structure of the UI could quickly get complex in this game but it also needed to convey some of the magical mood of the game.'
                    }
                ]
            }
        ]
    }
];
